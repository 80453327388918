import Cookies from 'js-cookie'
import jwt from 'jsonwebtoken'

export const getAuthentication = () => jwt.decode(Cookies.get('jwt'))

export const logout = () => Cookies.remove('jwt')

export const isAuthenticated = () => {
    const jwt = getAuthentication()
    console.log(jwt)
    if(!jwt)
        return false
    const exp = jwt.exp
    return new Date().getTime() < exp * 1000
}

export const authenticate = async ({username, password}) => {
    const res = await fetch('/api/login', {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            username,
            password
        })
    })
    const resjson = await res.json()
    if(resjson.ok) {
        Cookies.set('jwt', resjson.jwt)
        return true
    } else {
        return false
        //throw new Error('Failed to authenticate')
    }
}