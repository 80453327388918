import React, { Component } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { getAuthentication, logout } from "../authentication";
import { withRouter } from "react-router-dom";

class Dash extends Component {
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
  }

  logout() {
    logout()
    this.props.history.push('/')
  }

  render() {
    return (
      <div className="p-grid">
        <Card className="p-col-12">
          Welcome {getAuthentication().username}!
          <Button onClick={this.logout} label="Sign  out" />
        </Card>
      </div>
    );
  }
}

export default withRouter(Dash);
