import React, { Component } from "react";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { authenticate } from "../authentication";
import { withRouter } from "react-router-dom";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(event) {
    event.preventDefault();
    let authenticated = await authenticate({
      username: this.state.username,
      password: this.state.password
    });
    if (authenticated) {
      this.props.history.push("/dash");
    } else {
      alert("failed to authenticate");
    }
  }

  render() {
    return (
      <div className="p-grid">
        <Card className="p-col-12">
          <form onSubmit={this.handleSubmit}>
            <InputText
              value={this.state.username}
              onChange={e => this.setState({ username: e.target.value })}
            />
            <InputText
              type="password"
              value={this.state.password}
              onChange={e => this.setState({ password: e.target.value })}
            />

            <Button type="submit" label="Sign In" />
          </form>
        </Card>
      </div>
    );
  }
}

export default withRouter(Login);
