import React from 'react';
import AuthRoute from './components/AuthRoute';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import './App.css';

import Login from './views/Login';
import Dash from './views/Dash';

function App() {
  return (
    <Router>
      <Route exact path="/" render={() => (
          <Redirect to="/dash"/>
      )}/>
      <Route path="/login" exact component={Login}/>
      <AuthRoute path="/dash" exact component={Dash}/>
    </Router>
  );
}

export default App;
